@font-face {
    font-family: "Montserrat";
    src:
        url("./assets/font/Montserrat-Regular.woff2") format("woff2"),
        url("./assets/font/Montserrat-Regular.woff") format("woff"),
        url("./assets/font/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: "Montserrat";
    src:
        url("./assets/font/Montserrat-Medium.woff2") format("woff2"),
        url("./assets/font/Montserrat-Medium.woff") format("woff"),
        url("./assets/font/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: "Montserrat";
    src:
        url("./assets/font/Montserrat-SemiBold.woff2") format("woff2"),
        url("./assets/font/Montserrat-SemiBold.woff") format("woff"),
        url("./assets/font/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-family: "Montserrat", Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.7em;
    padding-bottom: env(safe-area-inset-bottom);
}

.wrapper {
    position: relative;
    box-sizing: content-box;
    display: block;
    max-width: 138.6rem;
    padding: 0 4rem;
    margin: 0 auto;
}

.container {
    background: #fff;
    color: #000;
    transition:
        0.4s background ease-out,
        0.4s color ease-out;
}
.container.invert {
    background: #000;
    color: #fff;
}

a,
a:visited {
    text-decoration: none;
    color: inherit;
}

ul,
li {
    list-style: none;
}

img {
    max-width: 100%;
    max-height: 100%;
}

button,
button:disabled {
    color: inherit;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
}

main {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    padding: 16.1rem 0 14.8rem 0;
    margin-bottom: 100vh;
    background: #fff;
    transition: 0.2s background ease-out;
}
.container.invert main {
    background: #000;
}

@media only screen and (max-width: 900px) {
    .wrapper {
        padding: 0 3rem;
    }
}
@media only screen and (max-width: 700px) {
    .wrapper {
        padding: 0 2rem;
    }
    main {
        padding: 9.8rem 0 5.6rem;
    }
}
@media only screen and (max-width: 600px) {
    .wrapper {
        padding: 0 1.4rem;
    }
}
