.logo-carousel-wrapper {
    margin-top: 8.2rem;
}

.logo-carousel-title {
    position: relative;
    display: flex;
    justify-content: space-between;
    column-gap: 3.2rem;
    align-items: center;
    padding-bottom: 1.2rem;
    margin-bottom: -1.2rem;
    overflow: hidden;
}

.logo-carousel-title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0.3rem;
    width: 0;
    background: #000;
    transition: 0.3s all ease-out;
}

.logo-carousel-title:hover::after {
    width: 100%;
    background: #ffa3c7;
}

.logo-carousel-title h4 {
    font-weight: 500;
    font-size: 7.2em;
    line-height: 1em;
    text-transform: uppercase;
    transition: 0.3s color ease-out;
}

.logo-carousel-title:hover h4 {
    color: #ffa3c7;
}

.logo-carousel-title svg {
    width: 8.2rem;
    height: auto;
    -webkit-transform: translateX(-1.2rem);
    -ms-transform: translateX(-1.2rem);
    transform: translateX(-1.2rem);
    transition: 0.3s ease-out;
}
@media only screen and (max-width: 660px) {
    .logo-carousel-title {
        column-gap: 2.4rem;
    }
    .logo-carousel-title svg {
        height: 3.2rem;
        width: auto;
    }
}

.logo-carousel-title:hover svg {
    -webkit-transform: translateX(0rem);
    -ms-transform: translateX(0rem);
    transform: translateX(0rem);
}

.logo-carousel-title svg path {
    fill: #000;
    transition: 0.3s fill ease-out;
}

.container.invert .logo-carousel-title svg path {
    fill: #fff;
}

.logo-carousel-title:hover svg path,
.container.invert .logo-carousel-title:hover svg path {
    fill: #ffa3c7;
}

.logo-carousel {
    position: relative;
    margin-top: 16.2rem;
    margin-left: -8rem;
    overflow: visible;
    width: calc(100% + 16rem);
    z-index: 1;
}

.logo-carousel::after,
.logo-carousel::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 8rem;
    z-index: 2;
}

.logo-carousel::before {
    left: 0;
    background: -webkit-linear-gradient(90deg, #fff, rgba(255, 255, 255, 0));
    background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0));
}

.container.invert .logo-carousel::before {
    background: -webkit-linear-gradient(90deg, #000, rgba(0, 0, 0, 0));
    background: linear-gradient(90deg, #000, rgba(0, 0, 0, 0));
}

.logo-carousel::after {
    right: 0;
    background: -webkit-linear-gradient(-90deg, #fff, rgba(255, 255, 255, 0));
    background: linear-gradient(-90deg, #fff, rgba(255, 255, 255, 0));
}

.container.invert .logo-carousel::after {
    right: 0;
    background: -webkit-linear-gradient(-90deg, #000, rgba(0, 0, 0, 0));
    background: linear-gradient(-90deg, #000, rgba(0, 0, 0, 0));
}

.logo-carousel .slide {
    height: 12rem;
}

.logo-carousel figure {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
}

.container.invert .logo-carousel figure {
    -webkit-filter: invert(1);
    filter: invert(1);
}

@media only screen and (max-width: 1480px) {
    .logo-carousel {
        width: 100%;
        overflow: hidden;
        margin-left: 0;
    }
}

@media only screen and (max-width: 900px) {
    .logo-carousel {
        margin-top: 6.4rem;
    }

    .logo-carousel-title h4 {
        font-size: 2.4em;
        line-height: 1.5;
        max-width: 14ch;
    }

    .logo-carousel-title img {
        width: 2.6rem;
    }
}

@media only screen and (max-width: 700px) {
    .logo-carousel figure {
        padding: 2.4rem;
    }

    .logo-carousel-wrapper {
        margin-top: 4rem;
    }
}

.logo-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0 8.2rem;
    row-gap: 9.6rem;
    column-gap: 3.2rem;
    margin-top: 16.1rem;
    justify-content: center;
    align-items: center;
}

.logo-grid .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.logo-grid .grid-item img {
    width: initial;
    height: auto;
}

@media only screen and (max-width: 1160px) {
    .logo-grid {
        grid-template-columns: repeat(
            auto-fill,
            minmax(min(20rem, 22rem), 1fr)
        );
        row-gap: 4.6rem;
        padding: 0;
    }
    .logo-grid .grid-item {
    }
}
@media only screen and (max-width: 760px) {
    .logo-grid {
        grid-template-columns: repeat(
            auto-fill,
            minmax(min(10rem, 14rem), 1fr)
        );
        row-gap: 3.2rem;
        column-gap: 2.4rem;
        margin-top: 8.2rem;
    }
    .logo-grid .grid-item img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 6rem;
        max-width: 10rem;
    }
}

@media only screen and (min-width: 330px) and (max-width: 560px) {
    .logo-grid {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 4.6rem;
    }
    .logo-grid .grid-item img {
        max-height: auto;
        max-width: auto;
    }
}
