.loading-container-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.loading-container .waves {
    position: absolute;
    width: 100vh;
    top: 50%;
    height: 14rem;
    transform-origin: center;
}
.loading-container {
    position: relative;
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    -webkit-animation: 2.1s presentFromRight ease-in-out;
    animation: 2.1s presentFromRight ease-in-out;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    background: #000;
    opacity: 1;
    color: #fff;
}

@-webkit-keyframes presentFromRight {
    from {
        -webkit-transform: translateX(calc(100% + 14rem));
        -ms-transform: translateX(calc(100% + 14rem));
        transform: translateX(calc(100% + 14rem));
    }
    30% {
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }
    85% {
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }
    100% {
        -webkit-transform: translateX(-130%);
        -ms-transform: translateX(-130%);
        transform: translateX(-130%);
    }
}
@keyframes presentFromRight {
    from {
        -webkit-transform: translateX(calc(100% + 14rem));
        -ms-transform: translateX(calc(100% + 14rem));
        transform: translateX(calc(100% + 14rem));
    }
    30% {
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }
    85% {
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }
    100% {
        -webkit-transform: translateX(-130%);
        -ms-transform: translateX(-130%);
        transform: translateX(-130%);
    }
}

.loading-vectors-wrapper {
    position: relative;
    display: block;
    height: max-content;
    width: max-content;
}
.loading-title {
    font-size: 3.2em;
    line-height: 1.2em;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    max-width: 70%;
    color: inherit;
}
@media only screen and (max-width: 1200px) {
    .loading-title {
        font-size: 2.4em;
    }
}
@media only screen and (max-width: 700px) {
    .loading-title {
        font-size: 1.5em;
    }
}
