.aboutBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-top: 0.3rem solid #000;
    padding: 4.8rem 0 9.6rem 0;
}
.aboutBox:not(:first-child) {
    margin-top: 3.2rem;
}
.container.invert .aboutBox {
    border-top-color: #fff;
}

.aboutBox:last-child {
    margin-bottom: -14.8rem;
}

.aboutBox h1,
.aboutBox h2,
.aboutBox h3 {
    font-weight: 600;
    font-size: 3.6em;
    line-height: 1.45em;
    text-transform: uppercase;
    padding-right: 6.4rem;
}

.aboutBox address {
    font-style: normal;
}

.aboutBox ul,
.aboutBox p {
    font-weight: 500;
    font-size: 1.8em;
    line-height: 1.65em;
}
.aboutBox a:hover {
    text-decoration: underline;
}

.bulleted-list li {
    position: relative;
}
.bulleted-list li::before {
    position: relative;
    content: "";
    width: 0.4rem;
    height: 0.4rem;
    vertical-align: middle;
    margin-inline: 0.5em;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background: #000;
    display: inline-block;
}
.container.invert .bulleted-list li::before {
    background: #fff;
}
.bulleted-list li.offset {
    margin-left: 2em;
}

.gallery-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    border-top: 0.3rem solid #000;
    column-gap: 6.4rem;
    padding-top: 4.8rem;
}
.gallery-title {
    font-size: 3.6em;
    line-height: 1.45em;
    text-transform: uppercase;
}
.gallery-header .tags {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 1.6rem;
}
.gallery-header .tags li {
    font-size: 1.8em;
    line-height: 1.33em;
    font-weight: 500;
    color: #4f4f4f;
}

@media only screen and (max-width: 900px) {
    .gallery-header {
        flex-direction: column;
        row-gap: 0.8rem;
    }
    .gallery-header .tags {
        row-gap: 0.8rem;
    }
}

@media only screen and (max-width: 700px) {
    .aboutBox {
        grid-template-columns: 1fr;
        padding: 1.9rem 0 6.4rem 0;
        row-gap: 2.4rem;
    }
    .aboutBox:last-child {
        margin-bottom: -5.6rem;
    }
    .aboutBox h1,
    .aboutBox h2,
    .aboutBox h3 {
        font-size: 2em;
        line-height: 1.6;
    }
    .aboutBox h1 br,
    .aboutBox h2 br,
    .aboutBox h3 br {
        display: none;
    }
    .aboutBox ul,
    .aboutBox p {
        font-size: 1.2em;
        line-height: 1.8;
    }
}

@media only screen and (max-width: 600px) {
    .gallery-header {
        padding-top: 1.6rem;
    }
    .gallery-title {
        font-size: 1.8em;
    }
    .gallery-header .tags li {
        font-size: 1.2em;
        line-height: initial;
    }
}
