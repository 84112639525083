.promo-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(42rem, 45.6rem), 1fr));
    flex-direction: unset;
    column-gap: 1.6rem;
    row-gap: 9.2rem;
    margin-bottom: 16.1rem;
}

.promo .media-wrapper {
    position: relative;
    width: 100%;
    background: #f5f5f5; /* Fallback */
    overflow: hidden;
    padding-top: 77%;
}

.promo .media-wrapper img {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.promo .media-wrapper iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 140.77%;
    min-height: 140.77%;
    width: auto;
    height: auto;
    pointer-events: none;
}

.promo .info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1.8rem;
    row-gap: 0.8rem;
    margin-top: 3.2rem;
    padding-top: 1.6rem;
    border-top: 0.3rem solid #000;
}

.promo .info h3 {
    font-size: 2.4em;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
}

.promo .info ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.6rem;
}

.promo .info ul li {
    font-size: 1.8em;
    line-height: 1.3em;
    color: #4f4f4f;
}

@media only screen and (min-width: 1100px) {
    .promo-wrapper {
        /* Display still three columns on 13 inch computers */
        grid-template-columns: repeat(3, 1fr);
    }
    .scrollDown {
        position: absolute;
        top: 65rem;
        right: 0;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        transform-origin: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 1.8rem;
        flex-wrap: nowrap;
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
    }

    .scrollDown span {
        font-size: 1.8rem;
        line-height: 1.3;
        letter-spacing: 1px;
        color: #4f4f4f;
    }
}

@media only screen and (min-width: 900px) {
    .enhancedCursor {
        position: fixed;
        opacity: 0;
        transform: translate(-100%, 0%);
        transition: opacity 0.2s ease-out;
        pointer-events: none;
    }

    .enhancedCursor span {
        width: 5.6rem;
    }

    .promo:hover ~ .enhancedCursor {
        opacity: 1;
    }
}

@media only screen and (max-width: 900px) {
    .enhancedCursor {
        display: none;
    }
}

@media only screen and (max-width: 700px) {
    .promo-wrapper {
        grid-template-columns: 100%;
        row-gap: 6.4rem;
        margin-bottom: 8.2rem;
    }

    .promo .info {
        flex-direction: column;
        margin-top: 2.4rem;
    }

    .promo .info h3 {
        font-size: 1.8rem;
        line-height: 1.3em;
    }

    .promo .info ul {
        row-gap: 0.8rem;
    }

    .promo .info ul li {
        font-size: 1.2em;
    }
}

@media only screen and (min-width: 860px) {
    .promo .info {
        flex-direction: column-reverse;
        row-gap: 0.8rem;
        border-top: 0;
        padding-top: 0;
        margin-top: 1.6rem;
    }

    .promo .info ul {
        justify-content: flex-start;
        column-gap: 1.6rem;
        row-gap: 0.4rem;
    }

    .promo .info ul li {
        font-size: 1.4em;
        line-height: 1.7;
    }

    .promo:hover .info h3 {
        text-decoration: underline;
    }
}
