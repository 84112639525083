.container {
    -webkit-animation: fadeIn 0.6s ease-in-out;
    animation: fadeIn 0.6s ease-in-out;
}
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateX(-3rem);
        -ms-transform: translateX(-3rem);
        transform: translateX(-3rem);
    }
    to {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateX(-3rem);
        -ms-transform: translateX(-3rem);
        transform: translateX(-3rem);
    }
    to {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}
nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 2.5rem 0;
    z-index: 2;
    -webkit-filter: invert(0%);
    filter: invert(0%);
}
nav.inverted {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}
nav .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menu-toggle {
    display: none;
}
nav .logo-box {
    position: relative;
    width: 6.2rem;
    height: auto;
}
nav .logo-box #logo {
    overflow: visible;
    width: inherit;
}
.logo-box #logo path {
    fill: #000;
    opacity: 1;
    -webkit-transform: translate(0, 0) rotate(0deg);
    -ms-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg);
    transition: 0.3s all ease-in-out;
}
.container.invert nav .logo-box #logo path {
    fill: #fff;
}
.logo-box:hover #logo > path {
    opacity: 0;
}
.logo-box:hover #logo > path:nth-child(1) {
    -webkit-transform: translate(-10%, -20%) rotate(34deg);
    -ms-transform: translate(-10%, -20%) rotate(34deg);
    transform: translate(-10%, -20%) rotate(34deg);
}
.logo-box:hover #logo > path:nth-child(2) {
    -webkit-transform: translate(15%, 20%) rotate(15deg);
    -ms-transform: translate(15%, 20%) rotate(15deg);
    transform: translate(15%, 20%) rotate(15deg);
}
.logo-box:hover #logo > path:nth-child(3) {
    -webkit-transform: translate(30%, -5%) rotate(-10deg);
    -ms-transform: translate(30%, -5%) rotate(-10deg);
    transform: translate(30%, -5%) rotate(-10deg);
}
.logo-box:hover #logo > path:nth-child(4) {
    -webkit-transform: translate(40%, 30%) rotate(70deg);
    -ms-transform: translate(40%, 30%) rotate(70deg);
    transform: translate(40%, 30%) rotate(70deg);
}
.logo-box:hover #logo > path:nth-child(5) {
    -webkit-transform: translate(-25%, -5%) rotate(-15deg);
    -ms-transform: translate(-25%, -5%) rotate(-15deg);
    transform: translate(-25%, -5%) rotate(-15deg);
}
.logo-box:hover #logo > path:nth-child(6) {
    -webkit-transform: translate(10%, 15%) rotate(28deg);
    -ms-transform: translate(10%, 15%) rotate(28deg);
    transform: translate(10%, 15%) rotate(28deg);
}
.logo-box:hover #logo > path:nth-child(7) {
    -webkit-transform: translate(15%, -15%) rotate(0);
    -ms-transform: translate(15%, -15%) rotate(0);
    transform: translate(15%, -15%) rotate(0);
}
.logo-box:hover #logo > path:nth-child(8) {
    -webkit-transform: translate(5%, 5%) rotate(45deg);
    -ms-transform: translate(5%, 5%) rotate(45deg);
    transform: translate(5%, 5%) rotate(45deg);
}
.logo-box:hover #logo > path:nth-child(9) {
    -webkit-transform: translate(15%, 30%) rotate(-10deg);
    -ms-transform: translate(15%, 30%) rotate(-10deg);
    transform: translate(15%, 30%) rotate(-10deg);
}
.logo-box:hover #logo > path:nth-child(10) {
    -webkit-transform: translate(50%, -15%) rotate(18deg);
    -ms-transform: translate(50%, -15%) rotate(18deg);
    transform: translate(50%, -15%) rotate(18deg);
}
.logo-box:hover #logo > path:nth-child(11) {
    -webkit-transform: translate(70%, -30%) rotate(-36deg);
    -ms-transform: translate(70%, -30%) rotate(-36deg);
    transform: translate(70%, -30%) rotate(-36deg);
}
.logo-box:hover #logo > path:nth-child(12) {
    -webkit-transform: translate(40%, 50%) rotate(17deg);
    -ms-transform: translate(40%, 50%) rotate(17deg);
    transform: translate(40%, 50%) rotate(17deg);
}
.logo-box:hover #logo > path:nth-child(13) {
    -webkit-transform: translate(60%, 30%) rotate(28deg);
    -ms-transform: translate(60%, 30%) rotate(28deg);
    transform: translate(60%, 30%) rotate(28deg);
}

.logo-box .logo-icon {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 50%;
    height: auto;
    transition: 0.3s all ease-out;
}
.logo-box .logo-icon path {
    fill: #000;
}
.container.invert nav .logo-box .logo-icon path {
    fill: #fff;
}
.logo-box:hover .logo-icon {
    visibility: visible;
    opacity: 1;
}

nav .wrapper .navList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 4rem;
}
nav .wrapper .navList .nav-item {
    font-size: 1.2em;
    line-height: 2em;
    text-transform: uppercase;
    font-weight: 600;
}
nav .wrapper .navList .nav-item:hover {
    text-decoration: underline;
}
nav .wrapper .navList .lang {
    margin-left: -2rem;
    padding-left: 2rem;
    border-left: 0.1rem solid #000;
}
.container.invert nav .wrapper .navList .lang {
    border-left-color: #fff;
}
nav .wrapper .navList .lang button {
    cursor: pointer;
    font-weight: 600;
}
nav .wrapper .navList .lang button:not(:first-child) {
    margin-left: 4rem;
}
nav .nav-footer {
    display: none;
}
footer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100vh;
    background: #000;
    color: #fff;
}
.container.invert footer {
    background: #ffa3c7;
    color: #000;
}
footer .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    position: relative;
}
footer .footerTitle {
    font-size: 7.2em;
    line-height: 1.2em;
    font-weight: 600;
    max-width: 20ch;
}
footer .mailTo {
    display: block;
    width: max-content;
    font-size: 2.4em;
    color: #ffa3c7;
    margin: 4.8rem 0 0 0;
}
.container.invert footer .mailTo {
    color: #000;
}
footer .mailTo:hover {
    text-decoration: underline;
}
footer .bottom {
    position: absolute;
    bottom: 6rem;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    column-gap: 4rem;
    font-weight: 500;
    padding-right: inherit;
    padding-left: inherit;
}
footer .bottom a {
    text-decoration: underline;
}
footer .bottom a:hover {
    text-decoration: none;
}
footer .copyright {
    font-size: 1.4em;
    line-height: 1em;
}
footer .social {
    font-size: 1.8rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 4.8rem;
}

@media only screen and (max-width: 900px) {
    nav {
        padding: 2rem 0;
    }
    nav#is-open {
        color: #fff;
        height: 100vh;
        padding-bottom: 4.2rem;
    }
    .container.invert nav#is-open {
        color: #000;
    }
    nav:before {
        content: "";
        position: fixed;
        display: block;
        width: 100vw;
        height: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: transparent;
        transition:
            0.4s height cubic-bezier(0.46, 0.29, 0.85, 0.49),
            0.2s background ease-out;
    }
    nav#is-open:before {
        background: #000;
    }
    nav#is-open:before {
        height: 100vh;
        background: #000;
    }
    .container.invert nav#is-open:before {
        background: #fff;
    }
    nav#is-open .wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "logo menu"
            "nav nav"
            "other other";
        align-items: flex-start;
        height: 100%;
        grid-template-rows: 3.2rem repeat(2, 1fr);
    }
    nav#is-open .logo-box {
        grid-area: logo;
    }
    nav#is-open .logo-box #logo {
        -webkit-filter: invert(100%);
        filter: invert(100%);
    }
    .menu-toggle {
        position: relative;
        display: block;
        width: max-content;
        margin-left: auto;
        outline: none;
        border: none;
        background: transparent;
        font-weight: 600;
        font-size: 1.2rem !important;
        line-height: 2em;
        text-transform: uppercase;
        cursor: pointer;
        visibility: visible;
        color: inherit;
        align-self: center;
    }
    nav#is-open .menu-toggle {
        visibility: hidden;
        color: transparent;
        grid-area: menu;
    }
    nav#is-open .navList li .nav-item,
    nav#is-open .menu-toggle,
    nav#is-open .lang button,
    nav#is-open .nav-footer {
        opacity: 0;
        visibility: hidden;
        -webkit-animation: navItemFade 0.5s cubic-bezier(0.2, 0.38, 0.74, 0.6);
        animation: navItemFade 0.5s cubic-bezier(0.2, 0.38, 0.74, 0.6);
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
    nav .wrapper .navList .nav-item.active,
    nav .wrapper .navList .lang button:disabled {
        color: #a1a1a1;
    }
    .container.invert nav .wrapper .navList .nav-item.active,
    .container.invert nav .wrapper .navList .lang button:disabled {
        color: #4f4f4f;
    }
    nav#is-open .logo-box {
        animation-delay: 0.4s;
    }
    nav#is-open .navList li:nth-child(1) .nav-item {
        animation-delay: 0.8s;
    }
    nav#is-open .navList li:nth-child(2) .nav-item {
        animation-delay: 1s;
    }
    nav#is-open .navList li:nth-child(3) .nav-item {
        animation-delay: 1.2s;
    }
    nav#is-open .lang button:nth-child(1) {
        animation-delay: 1.5s;
    }
    nav#is-open .lang button:nth-child(2) {
        animation-delay: 1.8s;
    }
    nav#is-open .nav-footer {
        animation-delay: 2.2s;
    }
    nav#is-open .menu-toggle {
        animation-delay: 0.4s;
    }
    @-webkit-keyframes navItemFade {
        from {
            visibility: hidden;
            opacity: 0;
        }
        to {
            visibility: visible;
            opacity: 1;
        }
    }
    @keyframes navItemFade {
        from {
            visibility: hidden;
            opacity: 0;
        }
        to {
            visibility: visible;
            opacity: 1;
        }
    }
    .menu-toggle::after,
    .menu-toggle::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.6rem;
        height: 0.2rem;
        background: #fff;
        visibility: hidden;
    }
    .container.invert .menu-toggle::after,
    .container.invert .menu-toggle::before {
        background: #000;
    }
    nav#is-open .menu-toggle::after,
    nav#is-open .menu-toggle::before {
        visibility: visible;
    }
    .menu-toggle::before {
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        -ms-transform: translate(-50%, -50%) rotate(-45deg);
        transform: translate(-50%, -50%) rotate(-45deg);
    }
    .menu-toggle::after {
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        -ms-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
    }
    nav .wrapper .navList {
        display: none;
    }
    nav#is-open .wrapper .navList {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        grid-area: nav;
        margin-top: 7.2rem;
        row-gap: 1.6rem;
    }
    nav#is-open .wrapper .navList .nav-item {
        font-size: 3.6em;
        line-height: initial;
    }
    nav .wrapper .navList .lang {
        padding-left: 0;
        border-left: none;
        margin: 3.2rem 0 0 0;
        font-size: 0.33em;
        line-height: 2em;
    }
    /*nav#is-open .wrapper .navList .nav-item.active,
  nav#is-open .wrapper .navList .nav-item:disabled {
    color: #a1a1a1;
  }
  nav#is-open.inverted .wrapper .navList .nav-item.active,
  nav#is-open.inverted .wrapper .navList .nav-item:disabled {
    color: #4f4f4f;
  }*/
    nav#is-open .nav-footer {
        grid-area: other;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        height: 100%;
        margin-top: auto;
    }
    nav#is-open .nav-footer-title {
        font-weight: 600;
        font-size: 1.9em;
        line-height: 1.5;
    }
    nav#is-open .nav-footer-cta {
        font-size: 1.4em;
        line-height: 1;
        font-weight: 500;
        margin-top: 0.8rem;
    }
    nav#is-open:not(.inverted) .nav-footer-cta {
        color: #ffa3c7;
    }
    nav#is-open .social {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 2.4rem;
        row-gap: 0.8rem;
        flex-wrap: wrap;
        margin-top: 4.8rem;
    }
    nav#is-open .social a {
        font-size: 1.2em;
        line-height: 1;
        font-weight: 500;
        text-decoration: underline;
    }
    footer .footerTitle {
        font-size: 4.6em;
    }
}

@media only screen and (max-width: 700px) {
    footer .bottom {
        flex-direction: column-reverse;
        row-gap: 3.2rem;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 600px) {
    nav .logo-box {
        width: 4.7rem;
    }
    footer .bottom {
        bottom: 8.2rem;
    }
    footer .footerTitle {
        font-size: 3.6em;
        line-height: 1.4;
        width: 90%;
    }
    footer .mailTo {
        margin-top: 1.6rem;
        font-size: 1.8em;
    }
    footer .social,
    footer .copyright {
        font-size: 1.2em;
        line-height: initial;
    }
}

.intro {
    margin-bottom: 14.1rem;
    font-size: 1.8rem;
    line-height: 1.65;
    max-width: 86ch;
}
.intro h1,
.intro h2 {
    display: inline;
    font-weight: 500;
    font-size: inherit;
    line-height: inherit;
}
@media only screen and (max-width: 660px) {
    .intro {
        font-size: 1.4rem;
        margin-bottom: 8.2rem;
    }
}
