.gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.6rem;
    margin-top: 7.2rem;
}
.gallery img {
    width: 100%;
    background: #fcfcfc;
    object-fit: cover;
    cursor: pointer;
}
.gallery .wide,
.gallery .bento {
    grid-column: span 2;
    aspect-ratio: 16/9;
    object-fit: cover;
}
.gallery .bento {
    aspect-ratio: auto;
}
.gallery .narrow {
    grid-column: span 1;
    object-fit: cover;
    aspect-ratio: 3/4;
}
.gallery .square {
    grid-column: span 1;
    aspect-ratio: 1/1;
    object-fit: cover;
}
.gallery .noratio {
    aspect-ratio: auto;
    object-fit: contain;
    grid-column: span 2;
}
.gallery .vimeoEmbed {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 56.25%;
}
.gallery .vimeoEmbed.narrow {
    position: relative;
    overflow: hidden;
}
.gallery .vimeoEmbed.narrow iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 110.77%;
    min-height: 110.77%;
    max-height: 100%;
}
.gallery .vimeoEmbed iframe {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
@media only screen and (max-width: 600px) {
    .gallery {
        margin-top: 3.2rem;
        grid-template-columns: 1fr;
        column-gap: 0;
        row-gap: 1.6rem;
    }
    .gallery .wide,
    .gallery .bento,
    .gallery .narrow,
    .gallery .noratio {
        grid-column: unset !important;
    }
}

/* Lightbox */
.lightbox button,
.lightbox button:focus {
    background: #fff;
    outline: none;
    font-size: initial;
    border: none;
    cursor: pointer;
}

.lightbox-overlay {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    background: #fff;
    -webkit-animation: 0.6s fadein ease-in;
    animation: 0.6s fadein ease-in;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.lightbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 6.1rem 20rem 4.4rem 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lightbox .navigation {
    position: absolute;
    top: calc(50% - 6.9rem);
    left: 1.6rem;
    right: 1.6rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background: #fff;
}
.lightbox .navigation button:first-child {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -o-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
}

.lightbox .close {
    position: fixed;
    display: block;
    top: 15%;
    right: 10%;
    width: 2.4rem;
    background: transparent;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
    -webkit-transform: rotate(0) scale(1);
    -moz-transform: rotate(0) scale(1);
    -o-transform: rotate(0) scale(1);
    -ms-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    z-index: 999;
}
.lightbox .close:hover {
    -webkit-transform: rotate(90deg) scale(1.2);
    -moz-transform: rotate(90deg) scale(1.2);
    -o-transform: rotate(90deg) scale(1.2);
    -ms-transform: rotate(90deg) scale(1.2);
    transform: rotate(90deg) scale(1.2);
}
.lightbox .close span {
    position: relative;
    display: block;
    width: 100%;
    height: 0.2rem;
    background-color: #000;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.lightbox .close:hover span {
    background-color: #1c1c1c;
}
.lightbox .close span::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    background-color: inherit;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.lightbox-slider {
    display: block;
    width: 100%;
}
.lightbox .slick-track {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.lightbox figure {
    position: relative;
    display: block;
    width: max-content;
    max-width: 100%;
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
}
.lightbox figure img.wide {
    aspect-ratio: 16/9;
}
.lightbox figure img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    max-height: 70vh;
}
.lightbox figure img.narrow {
    width: auto;
    max-width: 50rem;
    height: 100%;
    object-fit: unset;
}
.lightbox .vimeoEmbed {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 62.5%;
}
.lightbox .vimeoEmbed iframe {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1200px) {
    .lightbox {
        padding-right: 8.2rem;
        padding-left: 8.2rem;
    }
    .lightbox .close {
        position: absolute;
        top: 10.7rem;
        right: 1.6rem;
    }
}
@media only screen and (max-width: 900px) {
    .lightbox-overlay {
        padding-top: 8.2rem;
    }
    .lightbox button {
        display: none;
    }
    .lightbox {
        padding-right: 3rem;
        padding-left: 3rem;
    }
}
@media only screen and (max-width: 700px) {
    .lightbox {
        padding-top: 0;
        padding-right: 2rem;
        padding-left: 2rem;
    }
}
@media only screen and (max-width: 600px) {
    .lightbox {
        padding-top: 8.2rem;
        padding-right: 1.4rem;
        padding-left: 1.4rem;
    }
    .lightbox .close {
        top: 0;
        right: 1.5rem;
    }
    .lightbox figure img {
        max-height: unset;
        height: auto !important;
        max-width: 100% !important;
    }
    .lightbox figure img.narrow {
        object-fit: contain;
        width: 100%;
        height: auto;
    }
}
